import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Medailon,
  Media,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/fenstar/logo.svg"
import Top from "../../../images/reference/fenstar/top.jpg"
import Weby from "../../../images/reference/fenstar/tri-weby.png"
import Kariera from "../../../images/reference/fenstar/kariera.png"
import Bara from "../../../images/reference/fenstar/bara.png"
import MobileScreen from "../../../images/reference/fenstar/hp-mobile.png"
import DesktopScreen from "../../../images/reference/fenstar/hp-desktop.png"

const pozadi = {
  backgroundImage: `linear-gradient(rgba(24,25,26,0.95),rgba(2,3,4,0.4)),url(${Top})`,
}

const Fenstar = () => (
  <Layout>
    <Helmet>
      <title>
        Nové webové prezentace včetně náborového webu a intranetu pro FenStar |
        igloonet
      </title>
      <meta
        name="description"
        content="3 webové prezentace v různých v jazykových mutacích, včetně
            náborového webu a privátní zóny napojené na firemní ERP Microsoft
            Dynamics NAV postavené na CMS Drupal."
      />
    </Helmet>
    <ReferenceBanner
      styles={pozadi}
      logo={Logo}
      alt="zlatě napsané logo společnosti Fenstar"
      extodkaz="//fenstar.cz/"
      heading="Nové webové prezentace včetně intranetu a zaměstnanecké sekce"
      odstavec="červenec 2017 - současnost"
    />

    <ReferenceContainer>
      <Media
        iPhone={{
          backgroundSrc: MobileScreen,
          backgroundAlt:
            "zobrazení hlavní stránky webu fenstar.cz na mobilním zařízení",
        }}
        laptop={{
          backgroundSrc: DesktopScreen,
          backgroundAlt: "zobrazení hlavní stránky webu fenstar.cz",
        }}
      />

      <PaddingWrapper>
        <TitleH2>Cíle spolupráce</TitleH2>
        <p>
          FenStar s.r.o. už více než 25 let vyrábí kvalitní a bezpečná okna i
          dveře. Nedávno prošel výrazným rebrandingem a potřeboval pomoct s
          představením své nové identity zákazníkům i B2B partnerům. Vytvořili
          jsme proto{" "}
          <strong>
            mezinárodní webovou prezentaci včetně partnerské zóny a
            zaměstnanecké sekce
          </strong>
          .
        </p>
      </PaddingWrapper>

      <BlockImageTextHalf
        className="d-md-none"
        image={Weby}
        alt={
          "zobrazení hlavních stran webů fenstar.cz, fenstar-point.cz a premium-ouvertures.fr v jednom obrázku"
        }
        right
        hideImageOnSmall
      >
        <TitleH2>Analýza, wireframy a design</TitleH2>

        <p>
          Projekt se skládal ze tří webů ve třech jazykových mutacích (čeština,
          francouzština, němčina):
        </p>
        <ul>
          <li>
            <a
              href="//fenstar-point.cz/"
              rel="noreferrer"
              title="odkaz na web Fenstar point"
              target="_blank"
            >
              fenstar-point.cz
            </a>{" "}
            – podpora prodeje v rámci České republiky,
          </li>
          <li>
            <a
              href="//premium-ouvertures.fr/"
              rel="nofollow noreferrer"
              target="_blank"
              title="odkaz na web Premium Ouvertures"
            >
              premium-ouvertures.fr
            </a>{" "}
            – podpora prodeje a budování obchodní sítě ve Francii,
          </li>
          <li>
            <a
              href="//fenstar.cz/"
              rel="noreferrer"
              title="odkaz na web Fenstar"
              target="_blank"
            >
              fenstar.cz
            </a>{" "}
            – stránky výrobce s prezentací produktů, partnerskou zónou,
            intranetem a kariérní sekci.
          </li>
        </ul>
      </BlockImageTextHalf>
      <p>
        Při tvorbě webů je pro nás zásadní kvalitní analýza v podobě workshopů,
        kvalitativních dat a zodpovědně realizovaných person. Přestože tyto
        služby běžně jako igloonet nabízíme, klient už měl vlastního
        subdodavatele. Naštěstí šlo o dobře zpracované podklady, a my se mohli
        hned pustit do práce.
      </p>
      <p>
        <em>Product owner</em> klienta byl na proces vývoje připravený a dobře
        věděl, jaké informace chce na stránkách komunikovat. Společná tvorba
        wireframů tak proběhla zcela bez problémů. V návrhu „
        <em>pixel-perfect</em>” grafiky jsme vyladili barevnost jednotlivých
        webů (každý komunikuje v jiných tónech) a pustili se do práce. Vždy si
        dáváme záležet na{" "}
        <strong>
          jednoduchosti, srozumitelnosti a uživatelské přívětivosti
        </strong>
        .
      </p>
    </ReferenceContainer>

    <img
      src={Top}
      alt="bílý moderní dům s velkými okny"
      className="img-fluid d-none d-md-flex m-auto"
      loading="lazy"
    />

    <ReferenceContainer>
      <PaddingWrapper>
        <TitleH2>Shrnutí webu</TitleH2>
        <p>
          V igloonetu vždy dbáme na to, aby zákazník neskončil ve „
          <em>vendor lock-in</em>“, tedy nezůstal závislý na konkrétním
          dodavateli. Všechny weby jsme proto postavili na otevřeném redakčním
          systému Drupal 9, který zohledňuje například{" "}
          <a
            href="//owasp.org/www-project-top-ten/"
            rel="noreferrer"
            title="odkaz na web owasp.org"
            target="_blank"
          >
            OWASP TOP TEN
          </a>{" "}
          bezpečnostních rizik. Aktuálně jsou spuštěné v produkčním režimu a
          kromě průběžné správy probíhá i jejich rozvoj a přidávání nových
          funkcí. Řídíme se tím, co klient zrovna potřebuje.
        </p>
      </PaddingWrapper>

      <PaddingWrapper>
        <TitleH2>Na výsledek jsme pyšní z celé řady důvodů</TitleH2>
        <ul>
          <li>
            Vylepšili jsme redakční systém tak, aby klient nemusel k produktům
            stále dokola zadávat duplicitní informace. Postarali jsme se také o
            lepší <strong>prezentaci produktů</strong> směrem k firemním
            zákazníkům.
          </li>
          <li>
            Vytvořili jsme <strong>partnerskou zónu</strong>, která usnadnila
            komunikaci mezi FenStar a jeho B2B klienty. Z <strong>ERP</strong>{" "}
            systému teď pomocí <strong>Microsoft Dynamics NAV</strong> jednoduše
            importují objednávky, faktury a další potřebné informace.
            Implementace je realizována na úrovni zpracování exportu z databáze{" "}
            <strong>MS SQL server</strong> a následného importu do redakčního
            systému.
          </li>
          <li>
            Ze základů partnerské zóny jsme vytvořili{" "}
            <strong>zaměstnaneckou sekci</strong>. Funguje jako komunikační
            kanál mezi zaměstnanci a vedením společnosti, což se skvěle hodilo s
            ohledem na řešení problémů kolem COVID-19.
          </li>
          <li>
            Realizovali jsme také <strong>kariérní sekci</strong>, v které
            inzeráty spravují přímo HR pracovníci.
          </li>
        </ul>
      </PaddingWrapper>
    </ReferenceContainer>

    <img
      src={Kariera}
      alt="zobrazení kariérních stránek z webu fenstar.cz. Barevný banner složený z fotografií a pod ním výpis firemních benefitů."
      className="img-fluid d-none d-md-flex m-auto"
      loading="lazy"
    />

    <ReferenceContainer>
      <PaddingWrapper>
        <TitleH2>Závěr</TitleH2>
        <p>
          Přes velký rozsah projektu jsme díky dobře zpracované analýze a
          kvalitním designovým návrhům vytvořili weby, kterými je radost
          procházet. Jsou jednoduché, funkční a příjemné na pohled. Pokud před
          sebou máte složitý projekt, a nejste si jistí, zda ho celý zvládnete
          vlastními silami, obraťte se na tučňáky. Společně si s tím poradíme.
        </p>
      </PaddingWrapper>

      <PaddingWrapper>
        <Medailon
          image={Bara}
          text="Spolupráce s firmou igloonet mě příjemně překvapila a trvá již
          několik let. Jeden z nejdůležitějších bodů pro mě je, že se na ně můžu
          obrátit skutečně s jakýmkoliv dotazem a vždy mi poradí. Všechno vysvětlí
          na vysoké odborné úrovni, a společně pak hledáme to nejlepší řešení.
          Mnoho věcí, které jsme udělali několik let zpátky, se potvrdilo,
          případně stalo trendem."
          name="Ing. arch. Barbora Kľúčiková Krajčovičová"
          position="Marketing Specialist"
          alt=""
        />
      </PaddingWrapper>
    </ReferenceContainer>
    <ContactForm contact="sobol" />
  </Layout>
)

export default Fenstar
